.Login {
  display: flex;
  height: 100%;
  width: 100%;
}
.LoginImages::-webkit-scrollbar {
  display: none;
}
.LoginImages {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ck-editor__editable {
  min-height: 150px !important;
  max-height: 400px !important;
}

.LoginImage {
  width: 100%;
  height: 100%;
}
.LoginImages {
  width: 100%;

  display: flex;
  justify-content: center;
}

.imageSize {
  height: 20%;
  width: 20%;
  margin-top: 7%;
}

.top-edit-button button {
  background-color: black !important;
  outline: none;
  border: none;
  border-radius: 0px !important;
  color: white;
  width: 150px;
  height: 40px;
}
.edit-image button {
  border: none;
  outline: none;
  background-color: #f5f5f5 !important;
  border-radius: 50% !important;
}

/* Font page */

.fontscrollbar {
  background-color: white;
  height: 90%;
  overflow: hidden auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fontscrollbar::-webkit-scrollbar {
  display: none;
}

.comiksPage {
  background-color: #fff;
  height: 90%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.comiksPagesHeader {
  display: flex;
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  outline: 1em solid white;
  gap: 1em;
}

.welcomeconent {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Adjust the minmax values as needed */
  grid-auto-rows: 1fr;
  gap: 1em;
  justify-items: center;
  padding: 1em;
}

/* .welcomeconent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  width: 100%;
  gap: 1em;
  padding: 15px;
  grid-auto-rows: 1fr;
} */
/* .conent2:hover {
  background-color: #f5f5f5;
} */
.conent {
  width: 100%;
}
.conent1 {
  width: 100%;
  height: 100%;
  color: white;
}
.fontname {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 16px;
  /* color: #000000; */
  padding: 0.5em;
}

.Welcomemessage {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
  /* identical to box height */
  padding: 10px;

  color: #000000;

  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.Name {
  /* font-family: 'Poppins'; */
  /* font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px; */
  /* identical to box height */

  /* color: #000000; */
  margin: 0 1em;
}
.Number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;
  /* border-radius: 5px ; */
  display: block;
  text-align: center;
  border: 0.5px solid #000000;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);

  /* identical to box height */

  color: #ffffff;
}

.conent2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1em;
  border: 0.5px solid #000000;
  /* box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */

  /* box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15); */
}

.primeColor {
  background-color: #ffffff;
  color: black;
}
.secondaryColor {
  background-color: black;
  color: #ffffff;
}

.conentspan {
  font-size: clamp(0.5rem, 1.5vw, 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.indexconent {
  padding: 10px;
  height: 400px;
}
.logoconent {
  width: 100%;
  height: 100%;
  margin: 65px 0px 0px 10px;
}
.logoconent2 {
  height: 100%;
  display: flex;
  align-items: "center";
  justify-content: center;
  flex-direction: column;
  margin-top: -3.5rem;
  background-color: white;
  width: 329px;
  background-repeat: no-repeat;
  color: white;
  border-radius: 5px;
  border: 1px solid gray;
}
.imglogo {
  border-radius: 5px;
  margin-top: 16px 0px 0px 0px;
}
.logospan {
  text-align: center;
}

.type {
  background-color: blue;
}

/* Question page  */
@media only screen and (max-width: 1059px) and (min-width: 10px) {
  .Questionpageconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 100% !important;
    /* justify-content: center; */
    width: 100% !important;
    /* margin: 0 auto !important   ; */
    /* justify-content: space-around; */
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1060px) {
  .Questionpageconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 100% !important;
    /* justify-content: center; */
    width: 100% !important;
    /* margin: 0 auto !important   ; */
    /* justify-content: space-around; */
  }
}

.Questionpageconent {
  margin-top: 1vw;
  display: grid;
  grid-template-columns: 100%;
  /* justify-content: center; */
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  /* justify-content: space-around; */
}

/* comicsBook page  */
.fontnamecenter {
  /* align-items: center; */
  display: contents;
}

.button-button-view {
  text-transform: none;
  border-radius: 0;
  color: #000000;
  width: 120px;
  height: 25px;
  /* margin-top: 50px; */
  background-color: white;
  border: 1px solid black;
}

/* .cardDiv {
  display: inline-block;
  position: relative;
} */

/* .cardDiv img {
    transition: margin-top 0.3s ease;
  } */

/* .cardDiv:hover img {
  margin-top: -60px;
  box-shadow: #d3d3d3 3px 3px 3px;
  z-index: 1 !important;
  position: relative;
} */

.Comicsname {
  font-weight: bold;
  color: #000000;
}
.ComicsDiscerption {
  width: 200px;
  color: #000000;
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}

.clear:hover {
  background-color: gray;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

/* campaign  page  */

.hover-effect:hover {
  color: white;
  background-color: black;
}

/* search box 
  .css-kara30-MuiInputBase-root-MuiInput-root:after{
    border-bottom: #b90c0c  ;
  } */

.comicsBookDiv {
  display: flex;
  gap: 1rem;
  overflow: auto;
  /* padding: 0.5em; */
  scrollbar-width: thin;
  scrollbar-color: black #f5f5f5;
}
.cardsDiv {
  display: flex;
  gap: 1em;
}
.cardHead {
  display: flex;
  transform: rotate(180deg);
  align-items: center;
  font-weight: bold;
  writing-mode: vertical-lr;
  justify-content: center;
  /* padding: 1em; */
}
.pageCardDiv {
  margin: 10px;
}
.OptionDiv {
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
  justify-content: flex-start;
  gap: 0 1em;
  flex-wrap: wrap;
}

.comicsBookPageCard {
  border: 1px solid rgb(213, 213, 213);
  padding: 0.5em;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 310px; */
}

.variantImageUpload {
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* For Desktop View */
/* @media screen and (min-width: 1440px) {
  .welcomeconent {
    grid-template-columns: repeat(4, 1fr);
  }
} */

/* For Tablet View */
/* @media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .welcomeconent {
    grid-template-columns: repeat(2, 1fr);
  }
} */
/* For Mobile Portrait View */
/* @media screen and (max-device-width: 480px) and (orientation: portrait) {
  .welcomeconent {
    grid-template-columns: repeat(1, 1fr);
  }
} */

.PagesImageDiv {
  width: 180px;
  display: flex;
  justify-content: center;
}

.inputField {
  /* width: clamp(10rem, 48%, 50rem); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.campaignDiv {
  background-color: white;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.CampaignProductNames {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
}
.imageDiscriptionNameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.imageDiscriptionDiv {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  width: 100%;
}
.imageLabel {
  height: 55px;
  width: 55px;
}
.firstImage {
  height: 180px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkboxImageDiv {
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  position: relative;
}
.checkboxImageDiv::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition on hover */
}

.checkboxImageDiv:hover::after {
  opacity: 1; /* Show the overlay with full opacity on hover */
}
.checkboxImageDivRemove {
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  position: relative;
}
.preview {
  opacity: 0;
  z-index: -1;
}
.preeviewPosition {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
  height: 100%;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox {
  opacity: 0;
  z-index: -1;
}
.deleteImage {
  position: absolute;
  color: white;
  top: -5px;
  right: 3px;
  cursor: pointer;
  z-index: 2;
}
.deleteImage:hover {
  color: red;
}
.deleteImageRemove {
  position: absolute;
  color: transparent;
  top: -5px;
  right: 3px;
  cursor: pointer;
  z-index: 0;
}
/* .checkboxImageDiv:hover {
  background-color: black;
  opacity: 90%;
} */
.checkboxImageDiv:hover .preview,
.checkboxImageDiv:hover .checkbox {
  opacity: 1;
  z-index: 1;
}
.multipleImgUpload {
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 10rem;
}
.mediaDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 1em;
  width: 100%;
  flex: 0 1 auto;
  justify-content: center;
}
.mediaDivOutline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: clamp(10rem, 100%, 20rem);
  flex: 0 1 auto;
  justify-content: center;
}
.editoDivOutline {
  display: flex;
  flex: 1 1 50%;
  width: calc(98% - clamp(10rem, 100%, 20rem));
  flex-direction: column;
}
.images {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  height: clamp(12rem, 27vh, 15rem);
  gap: 10px;
  justify-content: center;
  /* flex: 1; */
  width: 100%;
}

.headerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 1em;
}
.imageDiv {
  /* padding: 2rem; */
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.image {
  height: auto;
  max-height: clamp(6rem, 9rem, 12rem);
  width: auto;
  max-width: clamp(6rem, 9rem, 12rem);
}
.sectionDiv {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Adjust the minmax values as needed */
  grid-auto-rows: 1fr;
  gap: 1em;
  justify-items: center;
  padding: 1em;
}
.newsectionDiv {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Adjust the minmax values as needed */
  grid-auto-rows: 1fr;
  gap: 1em;
  justify-items: center;
}

.sectionDivs {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Adjust the minmax values as needed */
  grid-auto-rows: 1fr;
  gap: 1em;
  justify-items: center;
  padding: 1em;
}
.cardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
  /* padding-bottom: 1em; */
  border: 1px solid #d5d5d5;
  /* box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 16px; */
  /* max-width: 16rem; */
  /* width: 225px; */
  /* height: 310px; */
  width: 100%;
  justify-content: space-between;
}

.edit {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.cardDivs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
  gap: 0.5em;
  /* box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 16px; */
  /* width: clamp(9rem, 13rem, 18rem); */
  border: 1px solid #d5d5d5;
  width: 100%;
  /* min-height: clamp(10rem, 17rem, 23rem); */
  /* height: 260px; */
  /* max-width: 16rem; */
  /* width: 180px; */
  /* width: 100%; */
}
.newcardDivs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
  /* box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 16px; */
  /* width: clamp(9rem, 13rem, 18rem); */
  border: 1px solid #d5d5d5;
  /* width: 100%; */
  /* min-height: clamp(10rem, 17rem, 23rem); */
  /* height: 260px; */
  /* max-width: 16rem; */
  /* width: 180px; */
  gap: 0.5em;
  width: 100%;
}

.cardFooter {
  display: flex;
  width: 100%;
  /* height: 100%; */
  gap: 0.5em;
  align-items: center;
  justify-content: space-between;
}

.cardNameAndSize {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cardName {
  text-overflow: ellipsis;
  overflow: hidden;
}

.NoDataFound {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

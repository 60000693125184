.dialogTitleForComicsLibrary {
  display: flex;
  justify-content: space-between;
}

.NoDataFoundForComicsLibrary {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionDivForComicsLibrary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em 10px;
  justify-items: center;
  /* padding: 1rem 0; */
  justify-content: center;
}
.cardDivsForComicsLibrary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 16px;
  width: clamp(15rem, 16rem, 18rem);
  min-height: clamp(10rem, 17rem, 23rem);
  /* margin: 1rem 0; */
  /* max-width: 16rem; */
  /* width: 180px; */
  /* width: 100%; */
}

.cardFooterForComicsLibrary {
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: space-between;
}
.cardNameAndSizeForComicsLibrary {
  display: flex;
  flex-direction: column;
}

.cardButtonForComicsLibrary {
  display: flex;
  align-items: center;
}

.imageForComicsLibrary {
  height: auto;
  /* max-height: 250px; */
  max-height: clamp(6rem, 9rem, 12rem);
  width: auto;
  /* max-width: 250px; */
  max-width: clamp(6rem, 15rem, 20rem);
}

.imageDivForComicsLibrary {
  padding: 2rem;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .sectionDivForComicsLibrary {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .sectionDivForComicsLibrary {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 400px) {
  .sectionDivForComicsLibrary {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Editor common Css file */
.head_button {
  border: 1px solid black;
  height: 36px;
  width: 60px;
}

.head_leftarrow {
  height: 20px;
  width: 12px;
  vertical-align: baseline;
  align-self: center;
}
.head_input {
  border: none;
  font-weight: 500;
  font-size: 26px;
  line-height: 16px;
  align-items: center;
}
.Savebutton {
  background-color: black;
  color: white;
}
.header {
  position: relative;
  height: 7%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
}
.head_align {
  display: flex;
  column-gap: 35px;
}

/* layer */
.layer_button {
  width: 95%;
  height: 40px;
  background-color: #000000;
  color: #fffffff5;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  /* font-size: large; */
}
.add01layer {
  background-color: #343434;
  width: 100%;
  height: 35px;
  color: #fffffff5;
  align-items: center;
  margin-top: 2px;
  font-size: large;
  text-align: start;
  display: flex;
  /* justify-content: space-between; */
  cursor: default;
}
.layerimg {
  height: 100%;
  width: 19px;
  margin-block-start: auto;
}
.imggap1 {
  display: flex;
  column-gap: 6px;
  margin-inline-start: 1.05%;
  width: 80%;
  height: 100%;
  align-items: center;
}
.imggap2 {
  display: flex;
  column-gap: 6px;
  margin-inline-end: 2.05%;
}
.layertext {
  font-size: 15px;
  letter-spacing: 1px;
  margin-left: 4px;
}

li {
  text-decoration: none;
}
.side-menu {
  position: absolute;
  left: 24%;
  border: 1px solid #353535;
  background-color: #343434;
  color: #fffffff5;
  row-gap: 20px;
  text-align: center;
  top: 11%;
  padding: 10px 4px 4px 4px;
  cursor: pointer;
  z-index: 1;
}
.sideimag {
  height: 13px;
  margin-block: 7px;
  z-index: 1;
}
.sidetext {
  font-size: x-small;
  z-index: 1;
}
.sidearrow {
  position: absolute;
  left: 22.3%;
  top: 12%;
  z-index: 1;
}
.sidearrowimg {
  height: 50px;
  z-index: 1;
}
.sidearroww {
  position: absolute;
  left: 102.3%;
  top: 10%;
  z-index: 1;
}
.sidearrowww {
  position: absolute;
  left: 102.3%;
  top: 70%;
  z-index: 1;
}
.sidearroww2 {
  position: absolute;
  left: 96.3%;
  top: 75px;
  z-index: 1;
}
.sidearrowimgg {
  height: 21px;
  z-index: 1;
}
/* fabricpage */

.fabricpage {
  width: 100%;
  height: 97%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.fabricposition {
  width: 66%;
  /* height: 80vh; */
  align-items: center;
  /* background-color: #f7f2f2; */
}

/* Edit tools */

.Aligntext {
  display: flex;
  justify-content: space-around;
  margin-block: 8px;
}
.Aligntext img {
  height: 18px;
}
.texttoolalign {
  text-align: start;
  margin-left: 16.5px;
}
.textposition {
  background-color: #7e7e7e;
  height: 3vh;
  margin-block: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.fabricposition div img {
  height: 17px;
  /* margin-right: 30px; */
}
/* .sample-canvas {
  width: 2894px !important;
  height: 4091px !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border: 3px solid black !important;
  box-sizing: border-box !important;
  position: relative;
} */

/* .sample-canvas {
  width: 816px !important;
  height: 1056px !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border: 3px solid black !important;
  box-sizing: border-box !important;
  position: relative;
}
.canvas-container {
  width: 816px !important;
  height: 1056px !important;
} */

.fabrictext {
  position: absolute;
  left: 79%;
  top: 89%;
}
.butn_img:hover {
  background-color: hsl(0, 7%, 86%) !important;
}
.bth_img:hover {
  background-color: hsl(0, 7%, 86%) !important;
}

/* textarea {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: -999;
  opacity: 0;
  width: 1px;
  height: 1px;
  font-size: 1px;
  padding-top: 0 !important;
} */

.css-yn7bsi-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0% !important;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #ffffff !important;
} */
/* ruler */
.newlayerscroll {
  height: 75vh;
  overflow-y: scroll;
}
.newlayerscroll::-webkit-scrollbar {
  display: none !important;
}

.scroll::-webkit-scrollbar {
  display: none !important;
}

.custom-input-container {
  display: flex;
  align-items: center;
  width: 50%;
  border: 1px solid grey;
  background-color: white;
  height: 40px;
}

.custom-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.arrow-container {
  display: flex;
  flex-direction: column;
}

.arrow {
  font-size: 20px;
  margin: 2px;
  cursor: pointer;
}

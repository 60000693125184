.actionButtonDiv {
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
  align-content: center;
}

.printButton {
  flex-direction: column;
}

.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.App::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 0;
  height: 0.6em;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(213, 213, 213);
  border-radius: 10px;
  margin: 1em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  border: 1px solid rgb(213, 213, 213);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /*    remove scroll bars     */
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.optiondropdown {
  max-height: 70px;
  overflow: auto;
  /* padding: 10px; */
}

.map {
  color: gray;
  border: gray;
}
.map:hover {
  background-color: gray;
  color: black;
}

.css-1vgqehx-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px !important;
}

.custom-header {
  background-color: #fff5f0;
  margin-top: 20px;
  border: none;
  /* Set your desired background color here */
  /* Other styles for the header cells */
}
.custom-row {
  background-color: inherit !important; /* Remove background color changes */
  border: none;
}

.no-border {
  border: none;
}

/* image hover image  */
.zoomable-image {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
}

.zoomable-image:hover {
  transform: scale(1.2); /* Increase the scale factor to zoom in more */
}

/*    lable input div    */
.lableInputDiv {
  display: flex;
  flex-direction: column;
  height: 6.5em;
}

/*    remove autofill background color     */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}

@font-face {
  font-family: "PoppinsBlack";
  src: url("./Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoBlack";
  src: url("./Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "VictorBlack";
  src: url("./VictorMono-VariableFont_wght.ttf") format("truetype");
}

table {
  width: 100%;
}
tbody:before {
  line-height: 1em;
  content: "\200C";
  display: block;
}
thead {
  height: 2rem;
  background-color: #f5f5f5;
}
td {
  background-color: #d2d2d2;
}
th,
td,
table {
  color: black;
  border: 1px solid #46464682;
  border-collapse: collapse;
}
.dropdownMappingDiv {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.deleteDropdown {
  cursor: pointer;
  background-color: rgb(138, 108, 108);
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: small;
}
.uploadImageDiv {
  cursor: pointer;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  background-color: #212020;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 4rem;
}
.dialogTitle {
  display: flex;
  justify-content: space-between;
}
.imageUploadLabel {
  cursor: pointer;
  display: flex;
  /* flex-direction: column; */
  background-color: #d9d9d9;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* width: 3rem;
  height: 3rem;
  flex: 0 1 18%; */
  /* margin: 1em; */
}

.Option {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  margin: 1px;
}
.Options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 0 0;
  flex-wrap: nowrap;
  align-items: center;
}

.OptionName {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.newCapaign {
  height: 100%;
  /* overflow: auto; */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  display: flex;
  gap: 1em;
  flex-direction: column;
}
.newCapaign::-webkit-scrollbar {
  display: none;
}

.campainForm {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.campainForm::-webkit-scrollbar {
  display: none;
}

.editorBody {
  max-height: 10rem;
}
.editorBody ::-webkit-scrollbar {
  width: 0.5em;
}

.editorDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
}
